<template>
    <div class="category">
        <BannerInnerPage />

        <div class="container category__breads">
            <Breads :links="breadLinks" />
        </div>

        <SectionListWrapper 
            v-for="(chapter, index) in categoryChapters" 
            :key="chapter.id" 
            :items="chapter.posts" 
            :caption="chapter.name" 
            :id="chapter.id"
            :uri="chapter.uri"
            :is-chapter="true"
            :slider="isMobile"
            :theme="index % 2 === 0 ? 'light' : 'white'" 
        />

        <EmptyList v-if="!categoryChapters.length" :message="$t('components.EmptyList.noChaptersInCategory')" />

        <Footer />
    </div>
</template>

<script>

import BannerInnerPage from "@/components/BannerInnerPage"
import SectionListWrapper from "@/components/SectionListWrapper"
import Footer from "@/components/Footer"

export default {
    components: {
        BannerInnerPage,
        SectionListWrapper,
        Footer
    },

    data() {
        return {
            category: null,
            breadLinks: [
                {
                    route: 'Category',
                    text: '~'
                },
            ],
            menuItems: []
        }
    },

    watch: {
        $route() {
            this.getCategory(this.$route.params?.uri)
        }
    },

    computed: {
        categoryChapters() {
            if (this.category?.pages) {
                return this.category?.pages.filter(p => {
                    if (p.posts.length) return p
                })
            }
            return []
        }
    },

    async mounted() {
        let uri = this.$route.params?.uri;
        await this.getCategory(uri)
    },

    methods: {
        getCategory(v) {
            return this.api.category.index(v).then(r => {
                this.category = r;
                this.breadLinks[0].text = r.name
            })
        }
    }
}
</script>

<style lang="scss" scoped>
    .category {
        &__breads {
            padding-top: 30px;
        }
    }
</style>